import React, { useState, useEffect } from 'react';
import Toolbar from '../Toolbar/Toolbar.js';
import { useAuth } from "../../hooks/useAuth.js";
import './SingleExpenseUpload.css';

const SingleExpenseUpload = () => {

  const { user } = useAuth();

  const getCurrentDate = () => {
    const today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
    return today.toISOString().split('T')[0];
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setMinutes(tomorrow.getMinutes() - tomorrow.getTimezoneOffset());
    return tomorrow.toISOString().split('T')[0];
  };
  
  const defaultExpense = {
    date: getCurrentDate(),
    description: '',
    amount: '',
    group_name: 'Personal',
    group_id: null,
    category: '',
    expense_user_breakdown: [
        {
          user_id: parseInt(user.userId, 10),
          amount: 0,
        },
    ],
    settled: true,
    is_recurring: false,
    recurring_schedule: {
      frequency: 'monthly',
      day_of_month: '',
      day_of_week: '',
      start_date: getTomorrowDate(),
      end_date: '',
      no_end_date: false
    }
  }

  const categories = [
    'Groceries', 
    'Dining Out', 
    'Household Supplies', 
    'Utilities', 
    'Subscriptions',
    'Insurance',
    'Gas', 
    'Travel',
    'Gifts',
    'Exercise Nutrition',
    'Entertainment & Activities',
    'Personal',
    'Baby',
    'Other',
  ];

  const [expense, setExpense] = useState(defaultExpense);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('Personal');

  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/get-user-groups`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: user.userId }),
        });
        const groupData = await response.json();
        setGroups(groupData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, [user.userId]);

  useEffect(() => {
    if (groups.length > 0) {
      const personalGroupId = groups.find(group => group.group_name === 'Personal')?.group_id || null;
  
      setExpense(prevExpense => ({
        ...prevExpense,
        group_id: personalGroupId,
      }));
    }
  }, [groups]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedExpense = { ...expense, [name]: value };
  
    if (name === 'amount') {
      const totalAmount = parseFloat(value || 0);
  
      // Update the breakdown for the current user
      updatedExpense.expense_user_breakdown = [
        {
          user_id: parseInt(user.userId, 10), // Add current user's ID
          amount: totalAmount, // Assign the total amount
        },
      ];
    }
  
    setExpense(updatedExpense);
  };
  

  const handleGroupChange = (event) => {
    const selectedGroupName = event.target.value;
    setSelectedGroup(selectedGroupName);

    const selectedGroup = groups.find(option => option.group_name === selectedGroupName);
    const selectedGroupId = selectedGroup ? selectedGroup.group_id : null;

    // Update the group and add the 'settled' property
    const updatedExpense = {
        ...expense,
        group_name: selectedGroupName,
        group_id: selectedGroupId,
        settled: selectedGroupName === "Personal", // Set 'settled' based on the group name
    };

    if (selectedGroupName === "Personal") {
        // For "Personal" group, ensure the user breakdown has the signed-in user
        updatedExpense.expense_user_breakdown = [{
            user_id: parseInt(user.userId, 10), // Ensure user_id is an integer
            amount: parseFloat(expense.amount || 0), // Assign the total amount to the user
        }];
    } else if (selectedGroupId) {
        // Get the selected group's users
        const selectedUsers = groups.filter(user => user.group_id === selectedGroupId);
        const totalAmount = parseFloat(expense.amount || 0);
        const userCount = selectedUsers.length;
        const baseAmount = (totalAmount / userCount).toFixed(2);
        const remainder = (totalAmount - baseAmount * userCount).toFixed(2);

        // Create breakdown array and adjust one user for rounding difference if necessary
        updatedExpense.expense_user_breakdown = selectedUsers.map((user, index) => ({
            user_id: parseInt(user.id, 10), // Ensure user_id is an integer
            amount: parseFloat(baseAmount) + (index === 0 ? parseFloat(remainder) : 0),
        }));
    } else {
        updatedExpense.expense_user_breakdown = [];
    }

    setExpense(updatedExpense);
};

  const handleAmountChange = (user_id, value) => {
    // Clone the current breakdown to avoid direct state mutation
    const currentBreakdown = [...(expense.expense_user_breakdown || [])];
  
    // Find the index of the user whose amount needs to be updated
    const index = currentBreakdown.findIndex(item => item.user_id === user_id);
  
    if (index !== -1) {
      // If the user already exists in the breakdown, update their amount
      currentBreakdown[index].amount = parseFloat(value); 
    } else {
      // If the user is not in the breakdown, add them with the specified amount
      currentBreakdown.push({ user_id, amount: parseFloat(value)});
    }
  
    // Update the expense state with the new breakdown
    const updatedExpense = { ...expense, expense_user_breakdown: currentBreakdown };
    setExpense(updatedExpense);
  };
  

  const handleCategoryChange = (event) => {
    const updatedExpense = { ...expense, category: event.target.value };
    setExpense(updatedExpense);
  };

  const groupOptions = Array.from(
    new Map(
      groups.map(group => [group.group_id, { group_id: group.group_id, group_name: group.group_name }])
    ).values()
  );

  const calculateNextExpenseDate = (startDate, frequency, dayOfMonth, dayOfWeek) => {
    const start = new Date(startDate + 'T00:00:00');
    
    if (frequency === 'monthly') {
      // If the start date is already the target day of month, use it
      if (start.getDate() === parseInt(dayOfMonth, 10)) {
        return startDate;
      }

      let targetMonth = start.getMonth();
      let targetYear = start.getFullYear();
      
      if (start.getDate() > parseInt(dayOfMonth, 10)) {
        targetMonth++;
        if (targetMonth > 11) {
          targetMonth = 0;
          targetYear++;
        }
      }

      let nextDate = new Date(targetYear, targetMonth, 1);
      const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0).getDate();
      const targetDay = Math.min(parseInt(dayOfMonth, 10), lastDayOfMonth);
      nextDate.setDate(targetDay);
      
      return nextDate.toISOString().split('T')[0];
    } else if (frequency === 'weekly') {
      // Weekly logic remains unchanged
      const targetDay = parseInt(dayOfWeek, 10);
      let nextDate = new Date(startDate + 'T00:00:00');
      
      if (nextDate.getDay() === targetDay) {
        return startDate;
      }
      
      while (nextDate.getDay() !== targetDay) {
        nextDate.setDate(nextDate.getDate() + 1);
      }
      
      return nextDate.toISOString().split('T')[0];
    }
    
    return null;
  };

  const handleSubmitExpense = async () => {
    try {
      // First, submit the base expense
      const response = await fetch(`${BASE_URL}/write-single-expense`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user.userId, expense }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error submitting expenses:', errorData);
        alert('Failed to submit expenses. Please try again.');
        return;
      }

      // If this is a recurring expense, submit the recurring configuration
      if (expense.is_recurring) {
        const nextExpenseDate = calculateNextExpenseDate(
          expense.recurring_schedule.start_date,
          expense.recurring_schedule.frequency,
          expense.recurring_schedule.day_of_month,
          expense.recurring_schedule.day_of_week
        );

        const recurringResponse = await fetch(`${BASE_URL}/write-recurring-config`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_id: user.userId,
            expense_template: {
              description: expense.description,
              amount: expense.amount,
              group_id: expense.group_id,
              group_name: expense.group_name,
              category: expense.category,
              expense_user_breakdown: expense.expense_user_breakdown,
              settled: expense.settled
            },
            recurring_schedule: {
              ...expense.recurring_schedule,
              next_expense_date: nextExpenseDate
            }
          }),
        });

        if (!recurringResponse.ok) {
          const errorData = await recurringResponse.json();
          console.error('Error setting up recurring expense:', errorData);
          alert('Expense saved but failed to set up recurring schedule. Please try again.');
          return;
        }
      }

      alert('Expense submitted successfully!');

      // Reset expense and selected group
      setExpense({
        ...defaultExpense,
        group_id: groups.find(group => group.group_name === 'Personal')?.group_id || null,
      });
      setSelectedGroup('Personal');
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting expenses.');
    }
  };
  
  

  const selectedGroupData = groupOptions.find(group => group.group_name === selectedGroup) || {};
  const selectedUsers = groups.filter(user => user.group_id === selectedGroupData.group_id) || [];

  const totalAssignedAmount = (expense.expense_user_breakdown || []).reduce(
    (sum, breakdown) => sum + parseFloat(breakdown.amount || 0),
    0
  );

  const remainingAmount = selectedGroup === 'Personal'
    ? 0
    : parseFloat(expense.amount || 0) - totalAssignedAmount;

  const handleRecurringChange = (event) => {
    setExpense(prev => ({
      ...prev,
      is_recurring: event.target.checked,
    }));
  };

  const handleRecurringScheduleChange = (event) => {
    const { name, value } = event.target;
    setExpense(prev => ({
      ...prev,
      recurring_schedule: {
        ...prev.recurring_schedule,
        [name]: value,
      }
    }));
  };

  const handleNoEndDateChange = (event) => {
    setExpense(prev => ({
      ...prev,
      recurring_schedule: {
        ...prev.recurring_schedule,
        no_end_date: event.target.checked,
        end_date: event.target.checked ? '' : prev.recurring_schedule.end_date
      }
    }));
  };

  const validateRecurringExpense = () => {
    if (!expense.is_recurring) return true;
    
    const {
      frequency,
      day_of_month,
      day_of_week,
      start_date,
      end_date,
      no_end_date
    } = expense.recurring_schedule;

    // Validate required fields
    if (!start_date) return false;
    if (!no_end_date && !end_date) return false;
    if (frequency === 'monthly' && !day_of_month) return false;
    if (frequency === 'weekly' && !day_of_week) return false;

    // Validate date logic
    if (!no_end_date && new Date(end_date) <= new Date(start_date)) return false;

    return true;
  };

  return (
    <div>
      <Toolbar />
      <div className="single-expense-container">
        <div className="single-expense-item">
          <h2>Create New Expense</h2>
          <label>
            Date:
            <input
              type="date"
              name="date"
              value={expense.date}
              onChange={handleChange}
            />
          </label>
          <label>
            Description:
            <input
              type="text"
              name="description"
              value={expense.description}
              onChange={handleChange}
            />
          </label>
          <label>
            Amount:
            <input
              type="number"
              name="amount"
              value={expense.amount}
              onChange={handleChange}
            />
          </label>
          <label>
            Group:
            <select value={selectedGroup || ''} onChange={handleGroupChange}>
              <option value="" disabled>Select Group</option>
              {groupOptions.map(group => (
                <option key={group.group_id} value={group.group_name}>
                  {group.group_name}
                </option>
              ))}
            </select>
          </label>
          <label>
            Category:
            <select value={expense.category || ''} onChange={handleCategoryChange}>
              <option value="" disabled>Select Category</option>
              {categories.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </label>

          {selectedGroup !== 'Personal' && (
            <>
              {selectedUsers.map(user => {
                const userBreakdown = expense.expense_user_breakdown?.find(item => item.user_id === user.id);
                const userAmount = userBreakdown ? userBreakdown.amount : 0;
                const totalAmount = parseFloat(expense.amount) || 1; // Default to 1 to prevent division by zero
                const percentage = ((userAmount / totalAmount) * 100).toFixed(2);

                return (
                  <div key={user.id}>
                    <label>
                      {user.user_name}:
                      <input
                        type="number"
                        value={userAmount}
                        onChange={e => handleAmountChange(user.id, e.target.value)}
                      />
                      <span> ({percentage}%)</span>
                    </label>
                  </div>
                );
              })}
              <div className="expense-summary">
                <p>Total Assigned: ${totalAssignedAmount.toFixed(2)}</p>
                <p>Remaining Amount: ${remainingAmount.toFixed(2)}</p>
              </div>
            </>
          )}

          <label className="recurring-checkbox">
            Recurring Expense
            <input
              type="checkbox"
              checked={expense.is_recurring}
              onChange={handleRecurringChange}
            />
          </label>

          {expense.is_recurring && (
            <div className="recurring-options">
              <label>
                Frequency:
                <select
                  name="frequency"
                  value={expense.recurring_schedule.frequency}
                  onChange={handleRecurringScheduleChange}
                >
                  <option value="monthly">Monthly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </label>

              {expense.recurring_schedule.frequency === 'monthly' ? (
                <label>
                  Day of Month:
                  <select
                    name="day_of_month"
                    value={expense.recurring_schedule.day_of_month}
                    onChange={handleRecurringScheduleChange}
                  >
                    <option value="">Select Day</option>
                    {[...Array(31)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </label>
              ) : (
                <label>
                  Day of Week:
                  <select
                    name="day_of_week"
                    value={expense.recurring_schedule.day_of_week}
                    onChange={handleRecurringScheduleChange}
                  >
                    <option value="">Select Day</option>
                    <option value="0">Sunday</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                  </select>
                </label>
              )}

              <div className="date-selectors">
                <label>
                  Start Date:
                  <input
                    type="date"
                    name="start_date"
                    min={getTomorrowDate()}
                    value={expense.recurring_schedule.start_date}
                    onChange={handleRecurringScheduleChange}
                  />
                </label>

                <div className="end-date-container">
                  <label>
                    End Date:
                    <input
                      type="date"
                      name="end_date"
                      min={expense.recurring_schedule.start_date || getTomorrowDate()}
                      value={expense.recurring_schedule.end_date}
                      onChange={handleRecurringScheduleChange}
                      disabled={expense.recurring_schedule.no_end_date}
                    />
                  </label>

                  <label className="no-end-date-checkbox">
                    <input
                      type="checkbox"
                      checked={expense.recurring_schedule.no_end_date}
                      onChange={handleNoEndDateChange}
                    />
                    No End Date
                  </label>

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="submit-expense-button">
        <button  
          disabled={!expense.description || !validateRecurringExpense()}
          onClick={handleSubmitExpense}>
          Submit Expenses
        </button>
      </div>
    </div>
  );
};

export default SingleExpenseUpload;
